import { useMemo } from 'react';

import { keepPreviousData, type QueryClient, useQuery } from '@tanstack/react-query';
import { formatFallbackTemplate, shouldShowFallbackTemplate } from '@vakantiesnl/components/src/utils/contentful';
import { getGeoEntitiesFromSlugs } from '@vakantiesnl/components/src/utils/geo';
import {
	buildContentfulSlug,
	getBrandedSearchTemplateQueryKey,
	postFormatBrandedTemplate,
} from '@vakantiesnl/services/src/contentfulTemplates';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { type GeoState, useGetGeo } from '@vakantiesnl/services/src/hooks/queries';
import { type FilterState, useFilterStore } from '@vakantiesnl/services/src/stores/filtersStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { type MicroCopy } from '@vakantiesnl/types/src';
import { useRouter } from 'next/router';

import {
	type BrandedSearchPageTemplate,
	type BrandedSearchTemplateResponse,
	DEFAULT_FALLBACK_TEMPLATE,
	getBrandedSearchTemplateResponse,
	mapBrandedSearchTemplate,
} from '../../contentful/brandedSearchTemplate';
import { fetchHandler } from '../../util/fetchHandler';

type BrandedSearchData = {
	brandedSearchTemplate: BrandedSearchPageTemplate | null;
};

export function useBrandedSearchTemplate(): BrandedSearchData {
	const geo = useGetGeo();
	const filters = useFilterStore((s) => s.filters);
	const microCopy = useMicroCopyContext();
	const { locale } = useRouter();

	const contentfulSlug = buildContentfulSlug(filters);
	const fallbackSlug = shouldShowFallbackTemplate(filters);

	const query = useQuery({
		queryKey: getBrandedSearchTemplateQueryKey(contentfulSlug || fallbackSlug || DEFAULT_FALLBACK_TEMPLATE),
		queryFn: () =>
			fetchHandler<BrandedSearchTemplateResponse>({
				fetchFn: () =>
					fetch('/api/contentful/brandedSearchTemplate', {
						method: 'POST',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ contentfulSlug, fallbackSlug, locale }),
					}),
				errorMessage: 'error fetching the branded template',
			}),
		select: (data) => {
			const mappedData = mapBrandedSearchTemplate(data.result);

			if (!mappedData || data.slug !== fallbackSlug) {
				return mappedData;
			}

			const { country, region, city } = getGeoEntitiesFromSlugs(filters, geo);
			const geoEntity = city[0] || region[0] || country[0];
			if (geoEntity && contentfulSlug && mappedData) {
				return formatFallbackTemplate(mappedData, geoEntity, contentfulSlug, filters, microCopy);
			}

			return null;
		},
		placeholderData: keepPreviousData,
		staleTime: getMinutesInMs(15),
	});

	const formattedTemplate = useMemo(() => {
		return postFormatBrandedTemplate(query.data, filters, contentfulSlug);
	}, [contentfulSlug, filters, query.data]);

	return {
		brandedSearchTemplate: formattedTemplate,
	};
}

export type BrandedSearchTemplateOptions = {
	contentfulSlug?: string;
	filters: FilterState;
	geo: GeoState;
	microCopy: MicroCopy;
	locale?: string;
};

export async function getBrandedSearchTemplate(
	queryClient: QueryClient,
	options: BrandedSearchTemplateOptions,
	hasLogsEnabled = false,
): Promise<BrandedSearchPageTemplate | null> {
	const { filters, contentfulSlug, geo, microCopy, locale } = options;
	const fallbackSlug = shouldShowFallbackTemplate(filters);

	const response = await getBrandedSearchTemplateResponse(contentfulSlug, fallbackSlug, locale, hasLogsEnabled);

	queryClient.setQueryData<BrandedSearchTemplateResponse>(
		getBrandedSearchTemplateQueryKey(contentfulSlug || fallbackSlug || DEFAULT_FALLBACK_TEMPLATE),
		response,
	);

	const mappedData = mapBrandedSearchTemplate(response.result);

	// When the result is from the fallback template, make sure we replace the placeholder data
	if (response.slug === fallbackSlug) {
		const { country, region, city } = getGeoEntitiesFromSlugs(filters, geo);
		const geoEntity = city[0] || region[0] || country[0];

		if (geoEntity && contentfulSlug && mappedData) {
			const fallbackTemplate = formatFallbackTemplate(mappedData, geoEntity, contentfulSlug, filters, microCopy);
			return postFormatBrandedTemplate(fallbackTemplate, filters, contentfulSlug);
		}
	}

	return postFormatBrandedTemplate(mappedData, filters, contentfulSlug);
}
